// Text Colors.
.text-color-primary{
    color: $COLOR-PRIMARY;
}

.text-color-gray{
    color: $C-GRAY;
}

.text-color-description{
    color: $COLOR-DESCRIPTION;
}