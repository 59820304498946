.badge{
    font-size: 12px;
    font-weight: bold;
    padding: 6px 14px;
    border-radius: 20px;
}

.badge-primary{
    background: $COLOR-FRAMER;
    color: $COLOR-TEXT-PRIMARY;
}