// Colors
$COLOR-PRIMARY: #8F9BA8;
$COLOR-SECONDARY: #0059FA;

// Colors -> Text
$COLOR-TEXT-PRIMARY: #0B0B0F;
$COLOR-DESCRIPTION: #BBBBBB;
$COLOR-FREE: #B7FF95;

// Colors -> Background
$BACKGROUND-PRIMARY: #0B0B0F;

// Colors -> Brands
$COLOR-FIGMA: #AB6AFF;
$COLOR-FRAMER: #FFFFFF;


// Colors -> Gray
$C-GRAY: #BBBBBB;

// Colors -> Themes -> Hubble
$COLOR-T-HUBBLE-PRIMARY: #CEFF66;


// Border Radius
$border-radius-section: 16px;
$border-radius-button: 10px;
$border-radius-thumbnail: 14px;
$border-radius-input: 6px;

// Gap
$gap: 24px; // desk
$gap-mobile: 16px; // ipads & mobile

// Breakpoint.
$BREAKPOINT-MOBILE: 768px;
$BREAKPOINT-IPAD: 1190px;