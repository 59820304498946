// form group.
.form-group{
    label{
        display: block;
        font-weight: 600;
        margin-bottom: 12px;
        padding-left: 30px;
    }
}

// form input.
.form-input{
    border: 1px solid rgb(143 155 168 / 30%);
    padding: 16px 20px;
    background: rgba(255, 255, 255, .05);
    border-radius: $border-radius-input;
    color: #FFFFFF;
    width: 100%;
    transition: all 400ms;

    &:focus{
        border-color: $COLOR-PRIMARY;
    }
}
