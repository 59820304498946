// Button.
.button {
    display: flex;
    align-items: center;
    gap: 8px;

    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 16px;

    border-radius: $border-radius-button;
    border: 2px solid transparent;

    padding: 20px 40px;

    transition: all 400ms;

    i{
        font-size: 22px;
    }
}

// Primary.
.button-primary {
    background: #FFFFFF;
    color: $COLOR-TEXT-PRIMARY;

    &:hover {
        background: $COLOR-PRIMARY;
        color: $COLOR-TEXT-PRIMARY;
    }

    // -> Framer
    &._framer {
        background: $COLOR-FRAMER;
        color: $COLOR-TEXT-PRIMARY;

        &:hover{
            background: $COLOR-PRIMARY;
            color: $COLOR-TEXT-PRIMARY;
        }
    }

    // -> Figma
    &._figma {
        background: $COLOR-FIGMA;
        color: #FFFFFF;

        &:hover {
            background: darken($COLOR-FIGMA, 15%);
        }
    }

    // -> Newsletter
    &._newsletter{
        background-color: $BACKGROUND-PRIMARY;
        color: #FFFFFF;
        font-size: 14px;
        padding: 14px 26px;

        &:hover {
            background: #FFFFFF;
            color: $COLOR-TEXT-PRIMARY;
        }
    }
}


// Button Outline
.button-outline {
    color: #FFFFFF;
    border: 1px solid #FFFFFF;

    &._preview {
        justify-content: space-between;
        max-width: 70%;

        @media(max-width: $BREAKPOINT-IPAD){
            max-width: 100%;
        }
    }

    &:hover{
        opacity: 1;
        background: #FFFFFF;
        color: $COLOR-TEXT-PRIMARY;
    }
}

// Button Close.
.button-close {
    position: absolute;
    top: 30px;
    right: 20px;
    color: #FFFFFF;
    z-index: 99;
    height: 28px;
    width: 28px;
    transition: all 400ms;

    svg{
        width: 100%;
        height: 100%;
    }

    &:hover {
        opacity: .6;
    }
}