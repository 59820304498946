.hubble-faq {
    padding: 100px 0;

    @media(max-width: $BREAKPOINT-MOBILE) {
        padding: 40px 0;
    }
}

.hubble-faq-questions {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 60%;
    margin: 40px auto 0 auto;

    @media(max-width: $BREAKPOINT-IPAD) {
        max-width: 100%;
    }
}


// Question
.hubble-faq-item-question {}

// Question Header.
.hubble-faq-question-header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 30px 24px 30px;
    background: rgba(143, 155, 168, 0.10);
    border-radius: 10px;

    i {
        font-size: 24px;
        color: $C-GRAY;
    }

    &.is-active {
        border-radius: 10px 10px 0 0;
        border: 1px solid rgba(143, 155, 168, 0.20);
        .hubble-faq-question-arrow {
            transform: rotate(180deg);
        }
    }
}

// Question Header Title.
.hubble-faq-question-header-title {
    font-size: 16px;
    line-height: 24px;
    transition: color 400ms;
    cursor: pointer;
    position: relative;
}

// Question Content
.hubble-faq-question-content {
    height: 0px;
    overflow: hidden;
    transition: height 400ms ease;
}

.hubble-faq-question-content-wrapper {
    border-radius: 0px 0px 10px 10px;
    border: 1px solid rgba(143, 155, 168, 0.20);
    border-top: 0;
    padding: 30px;
}