// Modal.
.modal {
    position: fixed;
    width: 400px;
    left: 50%;
    top: 50%;
    z-index: 9999;
    display: none;
    transform: translate(-50%, -50%);
    transition: all 500ms;

    &.is-active {
        display: initial;
    }

    @media(max-width: $BREAKPOINT-MOBILE){
        width: 100%;
    }
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $gap;

    background: linear-gradient(179.65deg, rgba(46, 52, 60, 0.94) 0.3%, rgba(25, 28, 31, 0.94) 119.39%);
    border: 1px solid rgba(255, 255, 255, 0.05);
    box-shadow: 8px 8px 16px rgba(143, 155, 168, 0.06);
    backdrop-filter: blur(8px);
    
    border-radius: $border-radius-section;

    text-align: center;
    
    padding: 40px;

    @media(max-width: $BREAKPOINT-MOBILE){
        height: 100vh;
        border-radius: 0;
        justify-content: center;
    }
}

.modal-heading{
    font-size: 24px;
    margin-bottom: 14px;
}