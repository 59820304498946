.hubble-header {
    width: 100%;
    padding: 24px 0;
}

// Content.
.hubble-header-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &._is-active{
        background: #000000;
        padding: 14px 20px;
        border-radius: 20px;
        border: 1px solid #ffffff0d;
    }

    @media(max-width: $BREAKPOINT-MOBILE){
        justify-content: center;
    }
}

// Logotipo.
.hubble-header-logotipo {
    height: 100%;
    img {
        width: 80px;
    }
}

// Navigation.
.hubble-header-navigation {
    display: flex;
    align-items: center;
    gap: 24px;
}

// Navigation -> List.
.hubble-header-navigation-list {
    display: flex;
    gap: 24px;
}

// Navigation -> List -> Item.
.hubble-header-list-item {
    a {
        font-size: 15px;
        font-weight: 500;
        color: $C-GRAY;
    }
}

.hubble-header-button-purchase {
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    background: rgba(143, 155, 168, 0.15);
    padding: 16px 24px;font-size: 15px;
    font-weight: bold;
    color: #fff;

    &:hover{
        background: $COLOR-T-HUBBLE-PRIMARY;
        color: #000;
        opacity: 1;
    }
}