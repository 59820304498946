.home-templates {
   
}

.home-templates-content {
    display: flex;
    flex-direction: column;
    gap: 60px;
    border: 1px solid $COLOR-PRIMARY;
    border-radius: $border-radius-section;
    padding: 80px;
    margin-bottom: 60px;

    @media(max-width: $BREAKPOINT-IPAD) {
        gap: 40px;
        padding: 40px;
    }
}

// Content -> Details.
.home-templates-details {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    @media(max-width: $BREAKPOINT-IPAD) {
        flex-direction: column;
    }
}

// Infos.
.home-templates-infos {
    display: flex;
    flex-direction: column;
    gap: $gap;

    max-width: 34%;

    @media(max-width: $BREAKPOINT-IPAD) {
        gap: $gap-mobile;
        max-width: 100%;
    }
}

// Infos -> Title -> Name.
.home-templates-name {
    font-size: 46px;
}

// Infos -> Title -> Category
.home-templates-category {
    color: $C-GRAY;
    margin-top: 12px;
    line-height: 24px;
}

// Buybox.
.home-templates-buybox {
    display: flex;
    flex-direction: column;
    gap: $gap;
    align-items: flex-end;

    @media(max-width: $BREAKPOINT-IPAD) {
        gap: $gap-mobile;
        align-items: flex-start;
    }
}

// Buybox -> Price.
.home-templates-buybox-price {
    font-size: 46px;

    &._free {
        color: $COLOR-FREE;
    }
}

// Buybox -> Group Buttons.
.home-templates-group-buttons {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media(max-width: $BREAKPOINT-IPAD) {
        width: 100%;
    }
}

// Content -> Galery.
.home-templates-galery {
    display: flex;
    gap: $gap;

    @media(max-width: $BREAKPOINT-IPAD) {
        flex-direction: column;
        gap: $gap-mobile;
    }
}

// Galery -> Thumbnail
.home-templates-thumbnail {
    img {
        max-width: 100%;
        border-radius: $border-radius-thumbnail;
    }
}