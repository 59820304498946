// Footer.
.footer{
    text-align: center;
    padding: 40px 0;

    .logotipo-icon{
        width: 20px;
        margin: 10px auto;

        svg{
            width: 100%;
            height: 100%;
        }
    }

    .description{
        color: $COLOR-DESCRIPTION;
        font-size: 14px;
        line-height: 20px;

        a{
            text-decoration: underline;
        }
    }
}

.footer-logotipo{
    width: 60px;
    margin: 10px auto;

    img{
        width: 100%;
    }
}