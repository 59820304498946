.menu-sticker {
    background: $COLOR-PRIMARY;
    border-radius: $border-radius-section;
    padding: 10px 20px;

    display: flex;
    align-items: center;
    gap: $gap;

    position: fixed;
    z-index: 9;

    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
}

// Logotipo
.menu-sticker-logotipo {
    background: $BACKGROUND-PRIMARY;
    border-radius: 8px;
    padding: 6px;
    width: 48px;
    height: 48px;

    svg {
        width: 100%;
        height: 100%;
    }
}

// Links
.menu-sticker-links {
    display: flex;
    gap: 18px;
}

.menu-sticker-link-item {
    a {
        font-size: 14px;
        font-weight: bold;
        color: $COLOR-TEXT-PRIMARY;
    }

    &._disabled{
        opacity: .6;
    }
}