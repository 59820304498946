.hubble-features {
    padding: 100px 0;

    @media(max-width: $BREAKPOINT-IPAD) {
        padding: 40px 0;
    }
}

.hubble-features-row {
    display: flex;
    gap: 60px;
    align-items: center;

    @media(max-width: $BREAKPOINT-IPAD) {
        flex-direction: column;
        gap: 24px;

        &._mobile-reverse {
            flex-direction: column-reverse;
        }
    }
}

// Heading.
.hubble-features-heading {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 80%;

    @media(max-width: $BREAKPOINT-IPAD) {
        width: 100%;
    }

    span {
        display: block;
    }
}

// Thumbnail.
.hubble-features-thumbnail {
    width: 60%;

    @media(max-width: $BREAKPOINT-IPAD) {
        width: 100%;

        img {
            width: 100%;
        }
    }
}


// List.
.hubble-features-list {
    padding: 0;
    margin: 24px 0 0 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: $gap;
}

// List -> Item.
.hubble-features-item {
    display: flex;
    align-items: start;
    gap: 8px;

    i {
        font-size: 22px;
        color: $COLOR-T-HUBBLE-PRIMARY;
    }
}

// List -> Item -> Content.
.hubble-features-item-content {
    b {
        display: block;
        font-size: 20px;
        margin-bottom: 8px;
    }
}