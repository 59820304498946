html {
    box-sizing: border-box;
    //overflow-x: hidden;
}

body {
    background: $BACKGROUND-PRIMARY;
    color: #FFFFFF;
    overflow-x: hidden;
    font-family: 'Inter', sans-serif;
    font-weight: 400;

    &.no-scroll{
        overflow-y: hidden;
    }
}

// Tags.
a {
    text-decoration: none;
    color: currentColor;
    transition: all 400ms;

    &:focus {
        outline: none;
    }

    &:hover{
        opacity: .6;
    }
}

small{
    font-size: 13px;
    line-height: 22px;
}

// Wrapper Default.
.wrapper {
    margin: auto;
    max-width: 1200px;
    position: relative;
    width: 100%;

    @media (max-width: 1199px) {
        max-width: 90%;
    }
}

// Wrapper Medium
.wrapper-medium{
    margin: auto;
    max-width: 1000px;
    position: relative;
    width: 100%;

    @media (max-width: $BREAKPOINT-IPAD) {
        max-width: 90%;
    }
}

// Wrapper Large
.wrapper-large{
    margin: auto;
    max-width: 90%;
    position: relative;
    width: 100%;

    @media (max-width: $BREAKPOINT-IPAD) {
        max-width: 90%;
    }
}


// Alinhamento.
.text-center{
    text-align: center;
}

.text-right{
    text-align: right;
}

// No Mobile.
.no-mobile{
    @media(max-width: $BREAKPOINT-IPAD) {
        display: none !important;
    }
}

// No Desk.
.no-desk{
    display: none !important;

    @media(max-width: $BREAKPOINT-IPAD) {
        display: block !important;
    }
}