.top-ventures{
    background: #27272a;
    color: #a3a3a3;
    padding: 10px 0;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    i{
        font-size: 14px;
    }

    
}

.top-ventures-column{
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: end;

    img{
        width: 60px;
        margin-right: 8px;
    }

    @media(max-width: $BREAKPOINT-MOBILE){
        justify-content: center;
    }
}