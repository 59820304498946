.bar-notification{
    background: $COLOR-T-HUBBLE-PRIMARY;
    color: $COLOR-TEXT-PRIMARY;
    padding: 10px 0;
    font-weight: bold;
    font-size: 14px;
}

.bar-notification-column{
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: center;

    svg{
        width: 20px;

        @media(max-width: $BREAKPOINT-MOBILE){
            display: none;
        }
    }

    @media(max-width: $BREAKPOINT-MOBILE){
        text-align: center;
    }
}