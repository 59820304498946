.home-introduction{
    position: relative;
    padding: 220px 0 140px 0;

    @media(max-width: $BREAKPOINT-MOBILE){
        padding: 160px 0 80px 0
    }
}

.home-heading{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 20px;

    max-width: 50%;

    text-align: left;

    .heading{
        line-height: 50px
    }

    .description{
        max-width: 90%;

        a{
            text-decoration: underline;
        }

        @media(max-width: $BREAKPOINT-MOBILE){
            max-width: 100%;
        }
    }

    @media(max-width: $BREAKPOINT-IPAD){
        max-width: 100%;
    }
}

.logotipo{
    width: 160px;
}

.link-subscribe-newsletter{
    font-weight: bold;
    display: block;
    font-size: 16px;
    line-height: 28px;
    margin: 20px 0; 

    span{
        background: $COLOR-PRIMARY;
        color: #000;
        padding: 2px 6px;
        border-radius: 2px;
        margin: 0 1.5px;
        text-transform: uppercase;
    }
} 