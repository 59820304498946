// Introduction
.hubble-introduction{
    padding: 60px 0 100px 0;

    @media(max-width: $BREAKPOINT-MOBILE){
        padding: 40px 0;
    }
}

// Header
.hubble-introduction-header{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $gap;
    padding: 0 100px;

    .description{
        font-size: 20px;
        padding: 0 180px;

        @media(max-width: $BREAKPOINT-IPAD){
            padding: 0;
        }
    }

    @media(max-width: $BREAKPOINT-IPAD){
        padding: 0;
    }
}

// Group Buttons CTA
.hubble-introduction-group-cta{
    display: flex;
    gap: 12px;
    width: 100%;
    justify-content: center;
    
    @media(max-width: $BREAKPOINT-MOBILE){
        flex-direction: column;
    }
} 

// Thumbnail
.hubble-introduction-thumbnail{
    width: 100%;
    margin: 80px 0 0 0;
    position: relative;

    img{
        width: 100%;
        border-radius: $border-radius-thumbnail;
        position: relative;
        z-index: 9;
    }

    .shadow{
        position: absolute;
        background: #6841EB;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 8px;
        filter: blur(60px);
        opacity: .5;
    }
}