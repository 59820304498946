// Global Font
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800&display=swap');

// Heading
.heading{
    font-size: 46px;
    line-height: 48px;

    @media(max-width: $BREAKPOINT-IPAD){
        font-size: 38px;
    }

    @media(max-width: $BREAKPOINT-MOBILE){
        font-size: 32px;
        line-height: 38px !important;
    }
}

// Description
.description{
    font-size: 16px;
    line-height: 28px;

    & + .description{
        margin: 20px 0;
    }

    &._regular{
        font-size: 14px;
        line-height: 24px;
    }
}
