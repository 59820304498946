// Introduction
.template-introduction {
    padding: 160px 0 100px 0;

    // Button 
    .button {
        font-size: 15px;
        padding: 20px 14px;
    }

    @media(max-width: $BREAKPOINT-MOBILE) {
        padding: 100px 0;
    }
}

// Columns.
.template-introduction-columns {
    display: flex;
    justify-content: space-between;
    gap: 40px;

    @media(max-width: $BREAKPOINT-IPAD) {
        flex-direction: column-reverse;
    }
}

// Infos
.template-introduction-infos {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 44px;
    max-width: 440px;

    .heading {
        font-size: 36px;
    }

    .description {
        font-size: 16px;

        @media(max-width: $BREAKPOINT-IPAD) {
            padding: 0;
        }
    }

    @media(max-width: $BREAKPOINT-IPAD) {
        padding: 0;
        max-width: 100%;
    }
}

// BuyBox
.template-introduction-buybox {
    display: flex;
    flex-direction: column;
    gap: 22px;
}

// Header
.template-introduction-header {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

// Build
.template-introduction-build {
    display: flex;
    align-items: center;
    gap: 8px;

    strong {
        padding-right: 4px;
    }

    img{
        width: 20px;
    }
}

// Group Buttons CTA
.template-introduction-group-cta {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    justify-content: start;

    @media(max-width: $BREAKPOINT-MOBILE) {
        flex-direction: column-reverse;
    }
}

// Group Button CTA > Subgroup.
.template-introduction-subgroup {
    display: flex;
    gap: 12px;
    width: 100%;

    a {
        width: 100%;
    }

    @media(max-width: $BREAKPOINT-IPAD) {
        flex-direction: column;
    }
}

// Thumbnail
.template-introduction-thumbnail {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    position: relative;

    img {
        width: 100%;
        border-radius: $border-radius-thumbnail;
        position: relative;
        z-index: 9;
    }
}

// Card -> Features -> Row.
.template-features-row {
    display: flex;
    flex-direction: row;
    gap: 24px;

    @media(max-width: $BREAKPOINT-MOBILE) {
        flex-direction: column;
        gap: 0;
    }
}

// Card -> Features -> List.
.template-features-list {
    margin: 24px 0 0 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media(max-width: $BREAKPOINT-MOBILE) {
        &:last-child {
            margin: 16px 0 0 0;
        }
    }
}

.template-features-item {
    display: flex;
    align-items: center;
    color: $COLOR-DESCRIPTION;
    font-weight: 600;
    gap: 8px;

    i {
        color: $COLOR-T-HUBBLE-PRIMARY;
    }
}

// Card -> Details
.template-details{
    display: flex;
    flex-direction: column;
    gap: 12px;

    div{
        display: flex;
        flex-direction: column;
        strong{
            font-size: 12px;
            display: block;
            text-transform: uppercase;
            opacity: .5;
        }
        .description{
            font-size: 14px;
        }
    }
}