// Logotipo
.modal-newsletter-logotipo {
    background: $BACKGROUND-PRIMARY;
    border-radius: 8px;
    padding: 6px;
    width: 48px;
    height: 48px;

    svg {
        width: 100%;
        height: 100%;
    }
}
 
// Form
.modal-newsletter-form{
    display: flex;
    flex-direction: column;
    gap: 12px;

    width: 100%;

    button{
        justify-content: center;
        font-size: 14px;
    }
}  