// Price
.hubble-price {
    padding: 100px 0;

    .heading {
        font-size: 72px;

        span {
            font-size: 42px;
            display: block;
            margin-top: 16px;
        }



        @media(max-width: $BREAKPOINT-MOBILE) {
            font-size: 52px;
        }
    }

    @media(max-width: $BREAKPOINT-MOBILE) {
        padding: 40px 0;
    }
}

// Card.
.hubble-price-card {
    border-radius: 20px;
    position: relative;
    margin: 60px auto 0 auto;
    padding: 1px;
    max-width: 75%;

    // Border Gradient
    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(90deg, #CEFF66 0%, #FFF 49.48%, #6239EB 100%);
        border-radius: 10px;
        z-index: -1;
    }

    @media(max-width: $BREAKPOINT-IPAD) {
        max-width: 100%;
    }
}

.hubble-price-card-content {
    display: flex;
    gap: 64px;
    align-items: center;
    justify-content: center;
    padding: 44px;
    flex-direction: row;
    background: rgba(11, 11, 15, 1);
    border-radius: 10px;

    @media(max-width: $BREAKPOINT-MOBILE) {
        flex-direction: column;
        gap: 24px;
        padding: 44px 24px;
    }
}

// Card -> Infos.
.hubble-price-card-infos {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    text-align: center;
    width: 30%;

    .button-primary {
        width: 100%;
        justify-content: center;
    }

    @media(max-width: $BREAKPOINT-MOBILE) {
        width: 100%;
    }
}

// Card. -> Content -> Title.
.hubble-price-card-title {
    text-transform: uppercase;
    text-align: center;

    span {
        margin-bottom: 6px;
        display: block;
        font-size: 62px;
    }
}

// Card -> Features -> Row.
.hubble-price-card-features-row {
    display: flex;
    flex-direction: row;
    gap: 24px;

    @media(max-width: $BREAKPOINT-MOBILE) {
        flex-direction: column;
        gap: 0;
    }
}

// Card -> Features -> List.
.hubble-price-features-list {
    margin: 24px 0 0 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media(max-width: $BREAKPOINT-MOBILE) {
        &:last-child{
            margin: 16px 0 0 0;
        }
    }
}

.hubble-price-features-item {
    display: flex;
    align-items: center;
    color: $COLOR-DESCRIPTION;
    font-weight: 600;
    gap: 8px;

    i {
        color: $COLOR-T-HUBBLE-PRIMARY;
    }
}