// Icons.
.icon {
    display: inline-block;
    width: 20px;
    height: 20px;

    svg{
        width: 100%;
        height: 100%;
    }
}