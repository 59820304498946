.hubble-included{
    padding: 60px 0;
}

.hubble-included-cards{
    display: flex;
    margin: 60px 0;
    gap: $gap;

    @media(max-width: $BREAKPOINT-MOBILE) {
        flex-direction: column;
    }
}

.hubble-included-cards-item{
    border-radius: 10px;
    position: relative;
    padding: 1px;
    width: 100%;

    .description{
        text-align: center;
        color: $C-GRAY;
        line-height: 24px;
    }

    // Border Gradient
    &:after{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(90deg, #CEFF66 0%, #FFF 49.48%, #6239EB 100%);
        border-radius: 10px;
        z-index: -1;
    }

    .shadow{
        position: absolute;
        background: #6841EB;
        width: 90%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 8px;
        filter: blur(24px);
        opacity: .5;
        z-index: -2;
    }
}

.hubble-included-cards-content{
    display: flex;
    gap: 16px;
    align-items: center;
    flex-direction: column;
    background: rgba(11, 11, 15, 1);
    padding: 34px;
    border-radius: 10px;
    height: 100%;
}

.hubble-included-card-title{
    font-size: 26px;
    text-align: center;

    span{
        display: block;
        color: $COLOR-T-HUBBLE-PRIMARY;
        font-size: 42px;
    }
}