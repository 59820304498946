// Gallery
.hubble-gallery{
    padding: 100px 0;

    .hubble-heading{
        max-width: 60%;
        margin: 0 auto;

        @media(max-width: $BREAKPOINT-IPAD) {
            max-width: 100%;

            .description{
                font-size: 18px;
            }
        }
    }

    @media(max-width: $BREAKPOINT-MOBILE) {
        padding: 40px 0;
    }
}

// List.
.hubble-gallery-list{
    display: flex;
    flex-direction: column;
    gap: $gap;
    margin: 80px 0;

    @media(max-width: $BREAKPOINT-MOBILE) {
        gap: $gap-mobile;
    }
}

// Theme.
.hubble-gallery-theme{
    display: flex;
    align-items: center;
    gap: 8px;
    text-transform: uppercase;
    letter-spacing: 2px;

    i{
        font-size: 22px;
    }
}

// List -> Row.
.hubble-gallery-row{
    display: flex;
    gap: $gap;

    @media(max-width: $BREAKPOINT-MOBILE) {
        flex-direction: column;
        gap: $gap-mobile;
    }
}

// List -> Item.
.hubble-gallery-item{
    width: 50%;

    img{
        width: 100%;
        border-radius: $border-radius-thumbnail;
    }

    @media(max-width: $BREAKPOINT-MOBILE) {
        width: 100%;
    }
}