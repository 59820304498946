// Theme Hubble
.theme-hubble {

    // Button Primary
    .button-primary {
        background: $COLOR-T-HUBBLE-PRIMARY;
        justify-content: center;
    }

    // Button Outline
    .button-outline{
        justify-content: center;
    }

    // Media Mobile.
    @media(max-width: $BREAKPOINT-MOBILE) {

        // Heading
        .heading {
            font-size: 36px;
            line-height: 40px;
        }
    }
}

.hubble-heading {
    text-align: center;

    .heading{
        @media(max-width: $BREAKPOINT-MOBILE) {
            font-size: 30px;
            line-height: 34px;
        }
    }

    .description {
        font-size: 20px;
        max-width: 50%;
        margin: 16px auto;

        @media(max-width: $BREAKPOINT-MOBILE) {
            max-width: 100%;
            font-size: 18px;
        }
    }
}